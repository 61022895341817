export default {
    name: '地点',
    url: '/admin/mini-temple-info', // rest接口表名
    query: '&sort=sort', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '地点名称', dataIndex: 'name'},
            {title: '地点简介', dataIndex: 'info'},
            {title: '封面图片', dataIndex: 'img', customRender: text => text ? <s-img src={text} /> : '--'},
            {title: '排序', dataIndex: 'sort'},
        ]
    },
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '地点名称',
            component: 'a-input',
            props: {
                placeholder: '请输入地点名称',
            },
            rules: [{ required: true, message: '请输入地点名称', trigger: 'blur' }]
        },
        {
            key: 'info',
            label: '地点简介',
            component: 'a-textarea',
            props: {
                placeholder: "地点简介",
                rows: 4
            },
            rules: [{ required: true, message: '请输入地点简介', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '封面图片',
            component: 'form-upload',
            props: {
                limit: 1
            },
            rules: [{ required: true, message: '请上传封面图片', trigger: 'change' }]
        },
        {
            key: 'title',
            label: '标题图片',
            component: 'form-upload',
            props: {
                limit: 1
            },
            rules: [{ required: true, message: '请上传标题图片', trigger: 'change' }]
        },
        {
            key: 'sort',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
        {
            key: 'content',
            label: '详细介绍',
            component: 'rich-editor',
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '地点名称',
                key: 'name',
            },
            {
                label: '地点简介',
                key: 'content',
                textarea: true
            },
            {
                label: '封面图片',
                key: 'img',
                img: true
            },
            {
                label: '排序',
                key: 'sort',
            },
            {
                label: '详细介绍',
                key: 'content',
                rich: true
            },
        ]
    }
}
